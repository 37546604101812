import React, { useState, useEffect }  from 'react'

import axiosInstance from '../utils/axiosInstance'
import Spinner       from './Spinner'
import { b64Encode } from '../utils/utils'
import { useAuth }   from '../auth/AuthContext'

import styles from './UserAccountSettings.module.css'


const UserAccountSettings = () => {

    const { resetTokens }                                   = useAuth();
    const [loadingStatus, setLoadingStatus]                 = useState(true);
    const [isSubscribed, setIsSubscribed]                   = useState(false);
    const [unsubscribeConfirmed, setUnsubscribeConfirmed]   = useState(false);
    const [isDeleting, setIsDeleting]                       = useState(false);
    const [deleteConfirmed, setDeleteConfirmed]             = useState(false);
    const [error, setError]                                 = useState('');
    const [subscriptionUuid, setSubscriptionUuid]           = useState('');


    const getSubscriptionStatus = async () => {
        try
        {
            const response = await axiosInstance.get('/api/subscription/status');
            const { isSubscribed, subscriptionUuid } = response.data;

            localStorage.setItem('MailingList', isSubscribed ? 'true' : 'false');

            setIsSubscribed(isSubscribed);
            setSubscriptionUuid(subscriptionUuid);
        }
        catch (err)
        {
            setError(err.message);
        }
        finally
        {
            setLoadingStatus(false);
        }
    };

    const handleUnsubscribe = async () => {

        if (!unsubscribeConfirmed)
            return;

        try
        {
            const requestUri = `/api/subscription/unsubscribe?s=${b64Encode(subscriptionUuid)}`;
            console.log(`unsub url: ${requestUri}`);

            localStorage.setItem('MailingList', 'false');

            await axiosInstance.get(requestUri);
            setIsSubscribed(false);
            setUnsubscribeConfirmed(false);
        }
        catch (err)
        {
            setError(err.message);
        }
    };

    const handleDeleteAccount = async () => {

        setIsDeleting(true);
        setError('');

        try
        {
            if (deleteConfirmed)
            {
                await axiosInstance.delete('/api/auth/deleteAccount');
                resetTokens();
                window.location.href = '/';
            }
        }
        catch (err)
        {
            setError(err.message);
        }
        finally
        {
            setIsDeleting(false);
        }
    };


    useEffect(() => {
        getSubscriptionStatus();
    }, []);


    if ( isDeleting )
    {
        return (
            <div className={styles.textContainer}>
                <h2>Sorry to see you go</h2>
                <Spinner sizeClass="medium" />
                <p>Come back anytime</p>
            </div>
        );
    }

    return (
    <>
        <div className={styles.textContainer}>

            <h2>Account Settings</h2>

            { error && <div className={styles.error}>{error}</div> }

            <div className={styles.settingSection}>
                <h3>Free Horoscope Emails</h3>
                { isSubscribed ? (
                    <>
                    <p>You are currently subscribed to the free horoscope mailing list.</p>
                    <input
                        type="checkbox"
                        checked={unsubscribeConfirmed}
                        onChange={(e) => setUnsubscribeConfirmed(e.target.checked)}
                        id="unsubscribeConfirmation"
                    />
                    <label htmlFor="unsubscribeConfirmation">I want to unsubscribe</label>
                    &nbsp;
                    <button onClick={handleUnsubscribe} disabled={!isSubscribed || !unsubscribeConfirmed || loadingStatus}>Unsubscribe</button>
                    </>
                ) : (
                    <p>You are not subscribed to daily horoscope emails.</p>
                )}
            </div>

            <div className={styles.settingSection}>
                <h3>Delete Your Account</h3>
                <p>Deleting your account is a permanent action that will result in the permanent loss of all products you may have purchased.</p>
                <input
                    type="checkbox"
                    checked={deleteConfirmed}
                    onChange={(e) => setDeleteConfirmed(e.target.checked)}
                    id="deleteConfirmation"
                />
                <label htmlFor="deleteConfirmation">I understand the consequences.</label>
                &nbsp;
                <button onClick={handleDeleteAccount} disabled={!deleteConfirmed || isDeleting}>Delete Account</button>
            </div>
        </div>
    </>
    );
};

export default UserAccountSettings;
