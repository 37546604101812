import React              from 'react';
import ReactDOM           from 'react-dom/client'

import { BrowserRouter }  from 'react-router-dom'
import { DndProvider }    from 'react-dnd'
import { HTML5Backend }   from 'react-dnd-html5-backend'


import App                from './App'
import { AuthProvider }   from './auth/AuthContext'
import ErrorBoundary      from './components/ErrorBoundary';


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <ErrorBoundary>
    <BrowserRouter>
        <AuthProvider>
            <DndProvider backend={HTML5Backend}>
                <App />
            </DndProvider>
        </AuthProvider>
    </BrowserRouter>
    </ErrorBoundary>
);
