
import React            from 'react'
import StarSignCard     from './StarSignCard'
import astrologySigns   from '../json/AstrologySigns.json'

import Styles from './StarSignGrid.module.css'


const StarSignGrid = () => {
    return (
    <div className={Styles.StarSignGrid}>
        {
            astrologySigns.astrologySigns.map((starSign, index) => (
                <StarSignCard
                    key={index}
                    starSign={starSign.name}
                    withLink={true}
                />
        ))}
    </div>
    );
};

export default StarSignGrid
