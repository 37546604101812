import React, {useEffect, useState} from 'react'
import { useParams }                from 'react-router-dom'

import { capitalize }               from '../utils/utils'

import axios                        from 'axios'
import StarSignCard                 from '../components/StarSignCard'
import SubscribeBox3                from '../components/SubscribeBox3'
import Spinner                      from '../components/Spinner'

import Styles from './ForecastPage.module.css';


const ForecastPage = () => {

    let { starSign } = useParams();
    starSign         = capitalize(starSign);

    const [dailyForecast, setDailyForecast]     = useState('');
    //const [weeklyForecast, setWeeklyForecast]   = useState('');
    const [dailyLoading, setDailyLoading]       = useState(true);
    //const [weeklyLoading, setWeeklyLoading]     = useState(true);

    /*const getStartOfWeek = () => {
        const today         = new Date();
        const startOfWeek   = new Date(today);
        startOfWeek.setUTCDate(today.getUTCDate() - today.getUTCDay());

        return startOfWeek;
    }*/

    const getDayForecastTitle = () => {
        const date          = new Date();
        const options       = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        const formattedDate = date.toLocaleDateString('en-US', options);

        // Correctly handle the formatted date
        const [weekday, month, day, year] = formattedDate.replace(',', '').split(' ');

        const ordinal = (n) => {
            const s = ["th", "st", "nd", "rd"];
            const v = n % 100;
            return n + (s[(v - 20) % 10] || s[v] || s[0]);
        };

        return `Daily Horoscope, ${weekday} ${ordinal(parseInt(day, 10))} ${month} ${year}`;
    };

    /*const getStartOfWeekTitle = () => {
        const startOfWeek = getStartOfWeek();

        // Format options for the date
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

        // Format the start of the week date using UTC
        const formattedDate = startOfWeek.toLocaleDateString('en-US', {
            ...options,
            timeZone: 'UTC'
        });

        // Split the formatted date to insert the ordinal indicator
        const [weekday, month, day, year] =
            formattedDate.replace(',', '').split(' ');

        const ordinal = (n) => {
            const s = ["th", "st", "nd", "rd"];
            const v = n % 100;
            return n + (s[(v - 20) % 10] || s[v] || s[0]);
        };

        return `Weekly Forecast for ${weekday} ${ordinal(parseInt(day, 10))} ${month} ${year}`;
    };*/


    useEffect(() => {

        const fetchDailyForecast = async () => {
            try
            {
                setDailyLoading(true);

                const response = await axios.get(
                    `${process.env.REACT_APP_API_ENDPOINT}/api/forecast`, {
                        params: {
                            starsign: starSign,
                            duration: 'day',
                            dateFrom: new Date().toISOString().split('T')[0]
                        }
                    });

                setDailyForecast(response?.data?.forecast);
            }
            catch (error)
            {
                console.error('Error fetching daily forecast:', error);
                setDailyForecast('Error fetching daily forecast.');
            }
            finally
            {
                setDailyLoading(false);
            }
        };

        // const fetchWeeklyForecast = async () => {

        //     /*{<h2> { getStartOfWeekTitle() } </h2>
        //     {
        //         weeklyLoading ?
        //         ( <Spinner sizeClass="small" /> ) :
        //         ( <div dangerouslySetInnerHTML={{__html: weeklyForecast}} /> )
        //     }}*/

        //     try
        //     {
        //         // setWeeklyLoading(true);

        //         const startOfWeek = getStartOfWeek();

        //         const response = await axios.get(
        //             `${process.env.REACT_APP_API_ENDPOINT}/api/forecast`, {
        //                 params: {
        //                     starsign: starSign,
        //                     duration: 'week',
        //                     dateFrom: startOfWeek.toISOString().split('T')[0]
        //                 }
        //             });

        //         // setWeeklyForecast(response.data.forecast);

        //     }
        //     catch (error)
        //     {
        //         console.error('Error fetching weekly forecast:', error);
        //         // setWeeklyForecast('Error fetching weekly forecast.');
        //     }
        //     finally
        //     {
        //         //setWeeklyLoading(false);
        //     }
        // };

        fetchDailyForecast();
        // fetchWeeklyForecast();

    }, [starSign]);

    return (
    <>
    <div id="top"></div>
    <div className={Styles.page}>

        <div className={Styles.forecastContainer}>

            <div className={Styles.pageHeaderBar}>
                <div className={Styles.column} style={{ width: '100%' }}>
                    <h1>{ starSign } Forecast </h1>
                    <h2> { getDayForecastTitle() }</h2>
                    <StarSignCard starSign={starSign} withLink={true} />
                </div>
            </div>


            {
                dailyLoading ?
                (<Spinner sizeClass="small" />) :
                (
                    <>
                    <div dangerouslySetInnerHTML={{__html: dailyForecast}} />
                    <SubscribeBox3 />
                    </>
                )
            }

        </div>

    </div>
    </>
    )
}

export default ForecastPage
