import React            from 'react'
import { Link }         from 'react-router-dom'
import { HashLink }     from 'react-router-hash-link'

import SocialMediaLinks from './SocialMediaLinks'
import Styles           from './Footer.module.css'


const Footer = () => {
    return (
        <>
        <footer id="footer" className={Styles.footer}>
            <div className={Styles.container}>
                <div className={Styles.row}>
                    <div className={Styles.column}>
                        <HashLink smooth to="/#HeroSection">
                            <img
                                src="/img/squeezed-stargoddess.jpg"
                                alt="Star Goddess Horoscopes"
                                className={Styles.starGoddessImage}
                                loading="lazy"
                            />
                        </HashLink>

                        <div className={Styles.siteBanner}>
                            <h3>Illuminate your Path with</h3>
                            <h2>StarGoddess</h2>
                        </div>
                    </div>

                    <div className={Styles.column} style={{marginLeft: '1em', marginRight:'1em'}}>
                        <SocialMediaLinks />
                    </div>

                    <div className={Styles.column}>
                        <ul className={Styles.footerLinks}>
                            <li><HashLink smooth to="/#HeroSection">Home</HashLink></li>
                            <li><Link to="/sitemap.xml">Sitemap</Link></li>
                            <li><HashLink smooth to="/terms">Terms of Service</HashLink></li>
                            <li><HashLink smooth to="/privacy-policy">Privacy Policy</HashLink></li>
                            <li><HashLink smooth to="/refund-policy">Refund Policy</HashLink></li>
                        </ul>
                    </div>
                </div>

                <div className={Styles.row}>
                    <div className={`${Styles.copyrightMessage} ${Styles.centeredText}`}>
                        &copy; Copyright 2024 <a
                            rel="noopener noreferrer external nofollow"
                            target="_blank"
                            href="https://dramallama.ltd">DramaLlama Ltd.,</a> All rights reserved.
                    </div>
                </div>
            </div>
        </footer>
        </>
    );
};

export default Footer;
