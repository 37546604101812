import React, { useState, useEffect } from 'react'
import styles from './ClockComponent.module.css'

const ClockComponent = () => {

    const [currentTime, setCurrentTime] = useState(new Date());

    useEffect(() => {
        const timerId = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000);

        return () => clearInterval(timerId);
    }, []);

    return (
        <>
        <div className={styles.clockContainer}>
            <div className={styles.timeDisplay}>
                {currentTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' })}
            </div>
            <div className={styles.dateDisplay}>
                {currentTime.toLocaleDateString()}
            </div>
        </div>
        </>
    );
};

export default ClockComponent;
