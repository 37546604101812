import React, { useState } from 'react'

import Spinner  from './Spinner'
import styles   from './CustomButton.module.css'


const CustomButton = ({ children, onClick, ...props }) => {

    const [isLoading, setIsLoading] = useState(false);

    const handleClick = async (e) => {
        setIsLoading(true);
        try
        {
            await onClick(e);
        }
        finally
        {
            setIsLoading(false);
        }
    };

    return (
        <>
        <button
            className={styles.customBtn}
            onClick={handleClick}
            disabled={isLoading}
            {...props} >

            {isLoading && <Spinner sizeClass="tiny" />}
            <span style={{ visibility: isLoading ? 'hidden' : 'visible' }}>{children}</span>

        </button>
        </>
    );
};

export default CustomButton;
