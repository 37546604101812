import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState
} from 'react'

import { useNavigate }  from 'react-router-dom'

import {
    getAccessToken,
    getAccountLevel,
    saveTokens,
    clearTokens,
    clearSubscriptionStatus,
    isTokenValid,
    refreshAccessToken
} from '../utils/authUtils'


const AuthContext   = createContext();
const useAuth       = () => useContext(AuthContext);


const AuthProvider = ({ children }) => {

    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isAdmin, setIsAdmin]                 = useState(false);
    const [loading, setLoading]                 = useState(true);
    const navigate                              = useNavigate();


    const checkAuthenticated = useCallback(() => {

        const accessToken = getAccessToken();

        if (isTokenValid(accessToken))
        {
            setIsAuthenticated(true);
            setIsAdmin((getAccountLevel(accessToken) === 'admin') ? true : false);
            setLoading(false);
            return true;
        }
        else
        {
            setIsAuthenticated(false);
            setIsAdmin(false);
            setLoading(false);
            return false;
        }
    }, []);

    useEffect(() => {

        const autoRefreshToken = async () => {

            try
            {
                setLoading(true);
                await refreshAccessToken();
                checkAuthenticated();
            }
            catch(err)
            {
                setIsAuthenticated(false);
                setIsAdmin(false);
                setLoading(false);
                clearTokens();
                navigate('/');
            }
        }

         checkAuthenticated();

        // Refresh token every 55 minutes
        const autoRefreshMinutes = 55;

        // parseInt(`${process.env.REACT_APP_ACCESS_TOKEN_AUTO_REFRESH_MINS}`) || 55;
        // console.log(`REACT_APP_ACCESS_TOKEN_AUTO_REFRESH_MINS: ${process.env.REACT_APP_ACCESS_TOKEN_AUTO_REFRESH_MINS}`);

        const intervalId = setInterval(autoRefreshToken, autoRefreshMinutes * 60 * 1000);

        return () => clearInterval(intervalId);

    }, [navigate, checkAuthenticated]);


    const setToken = (accessToken, refreshToken) => {
        saveTokens(accessToken, refreshToken);
        checkAuthenticated();
    };

    const getToken = () => {
        return getAccessToken();
    }

    const resetTokens = () => {
        clearTokens();
        clearSubscriptionStatus();
    }


    return (
        <AuthContext.Provider value={{ isAuthenticated, isAdmin, checkAuthenticated, setToken, getToken, resetTokens }}>
            { ! loading && children }
        </AuthContext.Provider>
    );
};


export {
    useAuth,
    AuthContext,
    AuthProvider
}
