
const pwdComplexity = (password) => {
    const hasValidLength    = password.length >= 12 && password.length <= 99;
    const hasLetters        = /[a-zA-Z]/.test(password);
    const hasNumbers        = /\d/.test(password);
    const hasSpecialChars   = /[^a-zA-Z\d]/.test(password);

    return hasValidLength && hasLetters && hasNumbers && hasSpecialChars;
};


module.exports =  {
    pwdComplexity
}
