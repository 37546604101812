import React, { useState, useEffect }   from 'react'
import { useNavigate }                  from 'react-router-dom'
import axiosInstance                    from '../utils/axiosInstance'
import Spinner                          from '../components/Spinner'
import styles                           from './ConfirmSubscriptionPage.module.css'


const ConfirmSubscriptionPage = () => {

    const [loading, setLoading] = useState(true);
    const [error, setError]     = useState(null);
    const navigate              = useNavigate();

    const handleHomeClicked = () => {
        navigate('/');
    }

    useEffect(() => {

        const confirmSubscription = async () => {
            try
            {
                await axiosInstance.get('/api/subscription/subscribe');
            }
            catch (err)
            {
                if (err.response && err.response.status === 409)
                {
                    setError('Your account is already subscribed!');
                }
                else
                {
                    setError(
                        'An error occurred while confirming your subscription. Please try again later.');
                }
            }
            finally
            {
                setTimeout(() => { setLoading(false); }, 3250);
                setLoading(false);
            }
        };

        confirmSubscription();
    }, []);


    return (
    <>
    <div className={styles.pageContainer}>
        <div className={styles.box}>
            { loading ? (
                <div className={styles.spinnerContainer}>
                    <Spinner sizeClass="tiny" />
                    <p className={styles.spinnerMessage}>Subscribing..</p>
                </div>
            ) : (
                <div className={styles.messageContainer}>
                    { error ? (
                        <p className={styles.errorMessage}>{error}</p>
                    ) : (
                        <>
                            <h2 className={styles.title}>Welcome to Your Daily Horoscope Forecast</h2>
                            <p className={styles.message}>You'll receive your horoscope in your inbox each day.</p>
                        </>
                    )}
                    <button className={styles.homeButton} onClick={handleHomeClicked}>
                        Home
                    </button>
                </div>
            )}
        </div>
    </div>
    </>
    );
};

export default ConfirmSubscriptionPage;
