import { isString } from '../utils/utils.js';

const emptyIdent = {
    name:       '',
    firstName:  '',
    surName:    '',
    dob:        '',
    pob:        { lat: 0, long: 0, name: '' },
    sex:        '',
    starsign:   '',
    profilePic: '',
    isPartner:  false,
    isComplete: false
};

const validateIdentity = (identity) => {
    const errors = {};

    if (!identity.name || !isString(identity.name)) {
        errors.name = 'Name is required and must be a string.';
    }

    if (!identity.dob || new Date(identity.dob) > new Date() || (new Date().getFullYear() - new Date(identity.dob).getFullYear()) < 18) {
        errors.dob = 'Date of birth is required and must be a valid date. You must be at least 18 years old.';
    }

    if (identity.pob.lat === 0 || identity.pob.long === 0 || identity.pob.name === '') {
        errors.pob = 'Place of birth is required and must be a valid location.';
    }

    if (!identity.sex || !['male', 'female'].includes(identity.sex)) {
        errors.sex = 'Sex is required and must be either "male" or "female".';
    }

    if (!identity.starsign || !isString(identity.starsign) || !['Aries', 'Taurus', 'Gemini', 'Cancer', 'Leo', 'Virgo', 'Libra', 'Scorpio', 'Sagittarius', 'Capricorn', 'Aquarius', 'Pisces'].includes(identity.starsign)) {
        errors.starsign = 'Starsign is required and must be a valid astrological sign.';
    }

    return errors;
};

export {
    emptyIdent,
    validateIdentity
};
