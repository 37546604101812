import React, { useState, useEffect }   from 'react'
import { useParams }                    from 'react-router-dom'
import axios                            from 'axios'
import Spinner                          from '../components/Spinner'
import { b64Encode }                    from '../utils/utils'
import { pwdComplexity }                from '../utils/password'
import styles                           from './ResetPasswordPage.module.css'

const ResetPasswordPage = () => {

    const { resetUuid }                         = useParams();
    const [uuid, setUuid]                       = useState('');
    const [password, setPassword]               = useState('');
    const [message, setMessage]                 = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isFormValid, setIsFormValid]         = useState(false);
    const [isSubmitting, setIsSubmitting]       = useState(false);
    const [isSuccess, setIsSuccess]             = useState(false);


    const handleSubmit = async (event) => {

        event.preventDefault();

        if (! isFormValid)
            return;

        let response;

        try
        {
            setIsSubmitting(true);

            const url =
                `${process.env.REACT_APP_API_ENDPOINT}/api/auth/reset-pass`;

            const encodedData = b64Encode(JSON.stringify({ uuid, password: b64Encode(password) }));

            response = await axios.post(url, { data: encodedData });

            setMessage('Password reset succeeded.');
            setIsSuccess(true);
        }
        catch (error)
        {
            switch (response?.status)
            {
            case 400:
                setMessage('Invalid reset request');
                break;

            case 410:
                setMessage('Password Reset timed out, please try again.');
                break;

            default:
            case 500:
                setMessage('Server error while processing request');
                break;
            }

            setIsSuccess(false);
        }
        finally
        {
            setIsSubmitting(false);
        }
    };

    useEffect(() => {
        if (resetUuid)
            setUuid(resetUuid);
    }, [resetUuid]);

    useEffect(() => {
        setIsFormValid(
            password === confirmPassword && pwdComplexity(password)
        );
    }, [password, confirmPassword]);


    return (
        <>
        <div className={styles.page}>
            {isSuccess ? (
                <div className={styles.messageContainer}>
                    <p>{message}</p>
                </div>
            ) : (
                <div className={styles.container}>
                    <h2>Reset Your Password</h2>
                    <p>Please enter your new password twice to confirm it's correct.</p>

                    <form onSubmit={handleSubmit} className={styles.form}>
                        <input
                            type="password"
                            placeholder="New Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className={styles.input}
                        />
                        <input
                            type="password"
                            placeholder="Confirm New Password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            className={styles.input}
                        />

                        <p>
                            Password must be 12-99 chars and include letters, numbers, and one or more special chars
                        </p>
                        <button
                            type="submit"
                            disabled={!isFormValid || isSubmitting}
                            className={isFormValid && !isSubmitting ? styles.button : styles.buttonDisabled}
                        >
                            {isSubmitting ? <><Spinner sizeClass="tiny" /> Resetting...</> : 'Reset Password'}
                        </button>
                    </form>
                </div>
            )}
        </div>
        </>
    );
};

export default ResetPasswordPage;
