import React                    from 'react'
import { Helmet }               from 'react-helmet'

//import HeroSection              from '../components/HeroSection'
import HeroSection2              from '../components/HeroSection2'
import ForecastSection          from '../components/ForecastSection'
import YearAheadSection         from '../components/YearAheadSection'
import LoveCompatibilitySection from '../components/LoveCompatibilitySection'
import PersonalReadingSection   from '../components/PersonalReadingSection'
import AboutSection             from '../components/AboutSection'

import './LandingPage.css'


const LandingPage = () => {

    // const title         = `${process.env.REACT_APP_SITE_TITLE}`;
    const title         = "Personalized Astrology Readings & Horoscopes | StarGoddess Astrology"
    const websiteUrl    = 'https://www.StarGoddess.co'

    return (
    <>

    <Helmet>
        <title>{title}</title>
        <link rel="canonical" href={websiteUrl} />
    </Helmet>

    <div className="page">
        <HeroSection2 />
        <ForecastSection />
        <YearAheadSection />
        <LoveCompatibilitySection />
        <PersonalReadingSection />
        <AboutSection />
    </div>
    </>
    )
};

export default LandingPage

