import React, { useState }  from 'react'
import { useNavigate }      from 'react-router-dom'
import DatePicker           from 'react-date-picker'
import Spinner              from '../components/Spinner'

import { continueJourney }  from '../session/journeyLogic'
import { setJourneyData }   from '../session/journeyState'


import 'react-date-picker/dist/DatePicker.css'
import styles from './GetStartDatePage.module.css'


const GetStartDatePage = () => {

    const navigate                          = useNavigate();
    const [selectedDate, setSelectedDate]   = useState(new Date());
    const [loading, setLoading]             = useState(false); // State to manage loading


    const handleDateChange = (newDate) => {

        if (newDate) {
            const utcDate = new Date(Date.UTC(
                newDate.getFullYear(),
                newDate.getMonth(),
                newDate.getDate()
            ));

            setSelectedDate(utcDate);
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        setLoading(true);

        setJourneyData('yearAheadStartDate', selectedDate);
        continueJourney(navigate);
    };

    return (
    <>
    <div id="top"></div>

    <div className={styles.page}>

        <h1>Year-Ahead Reading</h1>
        <h2>Select a start date for your reading</h2>

        <form onSubmit={handleSubmit}>
            <div className={styles.datePickerContainer}>
                <DatePicker
                    onChange={handleDateChange}
                    value={selectedDate}
                    disabled={loading}
                    required
                />
            </div>

            <button
                type="submit"
                disabled={loading}
                className={`${styles.submitButton} ${loading ? styles.disabledButton : ''}`} // Add dynamic class
            >
                { loading ? <Spinner sizeClass="tiny" /> : null }
                Continue
            </button>

        </form>

    </div>
    </>
    );
};

export default GetStartDatePage;
