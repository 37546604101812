import React, { useState }          from 'react'
import UserProfile                  from './UserProfile'
import UserOrderHistory             from './UserOrderHistory'
import UserAccountSettings          from './UserAccountSettings'
import UserProfileForecast          from './UserProfileForecast'
import ViewReading                  from './ViewReading'
import SubscribeBox3                from './SubscribeBox3'


import Styles from './UserDashboard.module.css'

const UserDashboard = () => {

    const [activeTab, setActiveTab]                 = useState('dailyForecast');
    const [selectedOrderUuid, setSelectedOrderUuid] = useState('');

    const handleOrderSelect = (uuid) => {
        setSelectedOrderUuid(uuid);
        setActiveTab('selectedOrder');
    };

    const renderContent = () => {
        switch (activeTab)
        {
            case 'dailyForecast':
                return (
                    <>
                    <UserProfileForecast duration='day' />
                    <div className={Styles.subContainer}>
                        <SubscribeBox3 />
                    </div>
                    </>
                );

            case 'weeklyForecast':
                return (
                    <>
                    <UserProfileForecast duration='week' />
                    <div className={Styles.subContainer}>
                        <SubscribeBox3 />
                    </div>
                    </>
                );

            case 'accountSettings':
                return (
                    <>
                    <UserAccountSettings />
                    </>
                );

            case 'orderHistory':
                return (
                    <>
                    <UserOrderHistory onOrderSelect={handleOrderSelect} />

                    <div className={Styles.notice}>
                        <h3>Waiting for your Order?</h3>

                        <p>If you're waiting for your order, thank you for placing your order with StarGoddess! Your personalized reading is being carefully crafted by our celestial experts. 🌌</p>

                        <p>Please note that this process may take up to 4 hours as we align the stars to provide the most accurate and insightful guidance just for you.</p>

                        <p>As soon as your reading is ready, we’ll send it straight to your inbox. So, sit back, relax, and let the cosmos work their magic.</p>

                        <p>Thank you for your patience and trust in StarGoddess.co!</p>
                        <p>⭐🌟💖🌟⭐</p>
                    </div>
                    </>
                );

            case 'selectedOrder':
                return ( <ViewReading orderRequestUuid={selectedOrderUuid} /> );

            default:
                return <UserProfileForecast duration='day' />;
        }
    };

    return (
        <>
        <div className={Styles.page}>
            <div className={Styles.dashboardContainer}>

                <div className={Styles.sidebar}>

                    <UserProfile />

                    <div className={Styles.menuOptions}>
                        <button onClick={() => setActiveTab('dailyForecast')}>Your Free Daily Forecast</button>
                        <button onClick={() => setActiveTab('weeklyForecast')}>Your Free Weekly Forecast</button>
                        <button onClick={() => setActiveTab('orderHistory')}>Order History</button>
                        <button onClick={() => setActiveTab('accountSettings')}>Account Settings</button>
                    </div>

                </div>

                <div className={Styles.mainContent}>
                    { renderContent() }
                </div>
            </div>
        </div>
        </>
    );
};

export default UserDashboard
