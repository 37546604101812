import React, { useEffect, useRef }     from 'react'
import { useNavigate }                  from 'react-router-dom'
import axios                            from 'axios'
import { useAuth }                      from '../auth/AuthContext'
import { continueJourney }              from '../session/journeyLogic'
import Spinner                          from '../components/Spinner'


const AuthSuccessPage = () => {

    const { setToken, isAuthenticated } = useAuth();

    const navigate         = useNavigate();
    const hasNavigated     = useRef(false);


    const parseGetParams = () => {

        try
        {
            const params   = new URLSearchParams(window.location.search);
            const authCode = params.get('authCode');

            return { authCode } || null;
        }
        catch(err)
        {
            console.error(`⚠ Exception in AuthSuccessPage::parseGetParams\n${JSON.stringify(err)}\n\n`);
            return null;
        }
    };

    const fetchTokens = async (authCode) => {
        try
        {
            console.log(`authCode: ${authCode}\n`);

            const response = await axios.post(
                `${process.env.REACT_APP_API_ENDPOINT}/api/auth/getTokens`,
                { authCode }
            );

            return response.data;
        }
        catch (err)
        {
            console.error(
                '⚠ Exception in fetchTokens:\n',
                JSON.stringify(err, null, 2),
                '\n\n'
            );

            return null;
        }
    };


    useEffect(() => {

        const start = async () => {

            let signedIn = false;

            if (! isAuthenticated)
            {
                const { authCode } = parseGetParams();

                if (authCode)
                {
                    const tokenData = await fetchTokens(authCode);
                    if (tokenData)
                    {
                        const { accessToken, refreshToken } = tokenData;
                        setToken(accessToken, refreshToken);
                        signedIn = true;
                    }
                    else
                    {
                        navigate('/');
                    }
                }
                else
                {
                    navigate('/');
                }
            }

            if ((signedIn || isAuthenticated) && !hasNavigated.current)
            {
                hasNavigated.current = true;

                continueJourney(navigate);
            }
        };

        if (hasNavigated.current)
            return;

        start();

    }, [isAuthenticated, setToken, navigate]);

    return (
        <>
        <div className="page">
            <Spinner sizeClass="small" />
            Loading...
        </div>
        </>
    );
};

export default AuthSuccessPage;
