import React, {useEffect, useState} from 'react'

import { Bar } from 'react-chartjs-2'
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js'

import Styles from './EmailsByStarsignMtdGraph.module.css'


ChartJS.register(
    CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend
);


const EmailsByStarsignMtdGraph = ({graphData}) => {

    const [data, setData] = useState({
        totalEmailsToday:       0,
        totalEmailsThisWeek:    0,
        totalEmailsThisMonth:   0,
        emailsByStarSign:       {}
    });

    const barData = {
        labels: Object.keys(data?.emailsByStarSign),
        datasets: [{
            label: 'Emails by Star Sign this Month to Date',
            data:               Object.values(data?.emailsByStarSign),
            backgroundColor:    'rgba(53, 162, 235, 0.5)',
            borderColor:        'rgba(53, 162, 235, 1)',
            borderWidth:        1,
        }],
    };

    const barOptions = {
        scales:     { y: { beginAtZero: true } },
        plugins:    { legend: { position: 'top', } },
        responsive: true
    };

    useEffect(() => {
        if (graphData)
            setData(graphData);
    }, [graphData])


    return (
    <>
    <div className={Styles.graphContainer}>
        <div className={Styles.chartContainer}>
            <Bar data={barData} options={barOptions} />
        </div>
    </div>
    </>
    );
}

export default EmailsByStarsignMtdGraph
