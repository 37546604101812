import React from 'react'

import Header     from './components/Header'
import Footer     from './components/Footer'
import AppRoutes  from './routes'
import CookieConsentModal from './components/CookieConsentModal'

import './App.css'


const App = () => {

    return (
    <>
    <Header />
    <CookieConsentModal />
    <AppRoutes />
    <Footer />
    </>
    )
}

export default App
