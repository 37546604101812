import React from 'react'

import Styles from './ProfileButtons.module.css'

const ProfileButtons = ({ profile }) => {

    const getProfilePic = () => {

        if (profile?.profilePic)
            return profile.profilePic;

        return `/img/squeezed-default-${ profile?.sex }-profile.jpg`;
    };

    const getStarsignPic = () => {
        return `/img/starsigns/25/${ profile?.starsign?.toLowerCase() }.png`;
    }

    if (! profile)
        return;

    return (
    <>
    <div>
        <div className={Styles.profileButtons}>
            <img
                className={Styles.profileButton}
                src={getProfilePic()}
                alt={`${profile.name}'s profile`}
            />

            <img
                className={Styles.profileButton}
                src={getStarsignPic()}
                alt={`${profile.name} is a ${profile?.starsign}`}
            />
        </div>
    </div>
    </>
    )
}

export default ProfileButtons
