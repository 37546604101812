import React, { useState }      from 'react'
import styles                   from './RegisterPage.module.css'
import EmailRegistrationForm    from '../components/EmailRegistrationForm'

const RegisterPage = () => {

    const [showInterface, setShowInterface] = useState(true);

    const handleRegistrationSuccess = () => {
        setShowInterface(false);
    };

    const handleRegByAuthProvider = (provider) => {
        window.location.href =
            `${process.env.REACT_APP_API_ENDPOINT}/api/auth/${provider}`;
    };


    return (
        <>
        <div className={styles.page}>
            <h2 className={styles.title}>Create an Account</h2>

            {showInterface ? (
                <div className={styles.interface}>
                    <div className={styles.container}>
                        <h3>Via OAuth Provider</h3>
                        <button
                            onClick={() => handleRegByAuthProvider('google')}
                            className={`${styles.oauthButton} ${styles.google}`}
                        >
                            <img src="/img/google-color.svg" width="25px" alt="Google" className={styles.icon} />
                            Google
                        </button>
                    </div>
                    <div className={styles.container}>
                        <h3>Via Email</h3>
                        <EmailRegistrationForm onRegistrationSuccess={handleRegistrationSuccess} />
                    </div>
                </div>
            ) : (
                <p>Please check your email to complete account registration.</p>
            )}
        </div>
        </>
    );
}

export default RegisterPage
