import React        from 'react'
import Styles       from './AboutSection.module.css'

import './AboutSection.module.css'


const AboutSection = () => {
    return (
    <>
    <div id="AboutSection" className={Styles.aboutSection}>

        <img src="/img/squeezed-stars-inverted.jpg" alt="starfield" loading="lazy" className={Styles.backgroundImage} />

        <div className={Styles.textContainer}>
            <h2>Welcome to Star Goddess</h2>
            <p>
            Let us illuminate your path with the ancient wisdom of astrology.
            </p>

            <p>
            Our mission is to provide you with insightful and accurate horoscopes that
            guide you through life's journey.
            </p>

            <p>
            Whether you're seeking advice on love, career, health, or personal growth,
            our expert astrologers are here to help you navigate the cosmos.
            </p>
        </div>

        <div className={Styles.imageContainer}>
            <img src="/img/squeezed-beautiful-mystic.jpg" alt="About StarGoddess" className={Styles.aboutImage} loading="lazy" />
        </div>
    </div>
    </>
    )
}

export default AboutSection
