
const journeys = {

    "sign-in": {
        steps: [
            {
                stepName:   "Sign In",
                url:        `/signin`,
                linkType:   "href",
                complete:   0
            },

            {
                stepName:   "Collect Birthday Details",
                url:        "/get-personal",
                linkType:   "nav",
                complete:   0
            },

            {
                stepName:   "Dashboard",
                url:        "/dashboard#top",
                linkType:   "nav",
                complete:   0
            }
        ]
    },

    "register": {
        steps: [
            {
                stepName:   "Reg Options",
                url:        "/register",
                linkType:   "href",
                complete:   0
            },

            {
                stepName:   "Collect Birthday Details",
                url:        "/get-personal",
                linkType:   "nav",
                complete:   0
            },

            {
                stepName:   "Dashboard",
                url:        "/dashboard#top",
                linkType:   "nav",
                complete:   0
            }
        ]
    },

    "mailing-list-subscribe": {
        steps: [
            {
                stepName:   "Reg Options",
                url:        "/register",
                linkType:   "href",
                complete:   0
            },

            {
                stepName:   "Collect Birthday Details",
                url:        "/get-personal",
                linkType:   "nav",
                complete:   0
            },

            {
                stepName:   "Notify Subscribe Confirmation",
                url:        "/confirm-sub",
                linkType:   "nav",
                complete:   0
            },

            {
                stepName:   "Home",
                url:        "/",
                linkType:   "nav",
                complete:   0
            }
        ]
    },

    "year-ahead-purchase": {

        steps: [
            {
                stepName:   "Sign In",
                url:        `/signin`,
                linkType:   "href",
                complete:   0
            },

            {
                stepName:   "Collect Birthday Details",
                url:        "/get-personal",
                linkType:   "nav",
                complete:   0
            },

            {
                stepName:   "Collect Start Date",
                url:        "/get-start-date",
                linkType:   "nav",
                complete:   0
            },

            {
                stepName:   "Pay",
                url:        "/year-ahead-reading",
                linkType:   "nav",
                complete:   0
            }
        ],
    }
};

const getNextStep = (journeyName) => {

    const journey = journeys[journeyName];

    if (! journey)
    {
        throw new Error(`Journey '${journeyName}' not found`);
    }

    const nextStep =
        journey.steps.find((step) => step.complete === 0);

    return nextStep;
};


export {
    journeys,
    getNextStep
}
