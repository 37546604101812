import React    from 'react'
import styles   from './OrderCanceledPage.module.css'


const OrderCanceledPage = () => {
    return (
        <>
        <div id="page"></div>
        <div className={styles.container}>
            <div className={styles.column}>
                <h1 className={styles.title}>Order Canceled</h1>
                <h2 className={styles.subtitle}>Your order has been canceled</h2>
                <p className={styles.paragraph}>
                    We regret to inform you that your order has been canceled. If you believe this is an error or if you have any questions,
                    please contact our support team. We are here to help and ensure that you have a positive experience with our service.
                </p>
            </div>
        </div>
        </>
    );
};

export default OrderCanceledPage
