import React, { useState }  from 'react'
import { Link }             from 'react-router-dom'
import axios                from 'axios'
import HCaptcha             from '@hcaptcha/react-hcaptcha'
import Spinner              from './Spinner'
import { b64Encode }        from '../utils/utils'
import { pwdComplexity }    from '../utils/password'

import styles from './EmailRegistrationForm.module.css'


const EmailRegistrationForm = ({onRegistrationSuccess}) => {

    const [email, setEmail]                     = useState('');
    const [password, setPassword]               = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [isVerified, setIsVerified]           = useState(false);
    const [loading, setLoading]                 = useState(false);
    const [error, setError]                     = useState('');
    const [message, setMessage]                 = useState('');


    const handleEmailChange             = (e) => setEmail(e.target.value);
    const handlePasswordChange          = (e) => setPassword(e.target.value);
    const handlePasswordConfirmChange   = (e) => setPasswordConfirm(e.target.value);


    const handleVerificationSuccess = (token, ekey) => {
        setIsVerified(true);
        setError('');
        setMessage('');
    };

    const handleVerificationError = (err) => {
        setError('Captcha verification failed. Please try again.');
        console.log('HCaptcha Error:', err);
        setIsVerified(false);
        setMessage('');
    };

    const handleSubmit = async (e) => {

        e.preventDefault();

        if (! isVerified)
        {
            setError('Please complete the HCaptcha to prove you are not a robot.');
            setMessage('');
            return;
        }

        if (! pwdComplexity(password))
        {
            setError('Password must be 12-99 chars and include letters, numbers, and one or more special chars');
            setMessage('');
            return;
        }


        if (email && password && password === passwordConfirm )
        {
            setLoading(true);
            let response;

            try
            {
                const data  = b64Encode(JSON.stringify({ email, password }));
                response    = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/api/auth/rbm`, { data });

                if (response?.status === 200 && response?.data?.message === 'verify email account')
                {
                    setMessage('Please check your email to complete account registration.');
                    setError('');
                    onRegistrationSuccess();
                }
            }
            catch (err)
            {
                if (response?.status === 409)
                {
                    setMessage('An account with this email already exists. Please reset your password or try sigining in.');
                    setError('');
                }
                else
                {
                    console.error('Registration failed:', err);
                    setError('Registration failed. Please try again.');
                    setMessage('');
                }
            }
            finally
            {
                setLoading(false);
            }
        }
    };


    return (
        <>
        <form className={styles.form}>

            { error   && <div className={styles.error}>{error}</div> }
            { message && <div className={styles.message}>{message}</div> }

            <input
                type="email"
                placeholder="Email Address"
                value={email}
                onChange={handleEmailChange}
                className={styles.inputField}
            />
            <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={handlePasswordChange}
                className={styles.inputField}
            />
            <input
                type="password"
                placeholder="Confirm Password"
                value={passwordConfirm}
                onChange={handlePasswordConfirmChange}
                className={styles.inputField}
            />
            <HCaptcha
                sitekey={process.env.REACT_APP_HCAPTCHA_SITE_KEY}
                onVerify={handleVerificationSuccess}
                onError={handleVerificationError}
            />
            <button
                onClick={handleSubmit}
                disabled={/*!isVerified ||*/ !email || password !== passwordConfirm || loading}
                className={styles.blueButton}
            >
                { loading ? <Spinner sizeClass="tiny" /> : "Register"}
            </button>

            <ul>
                <li> <Link to="/signin">sign in</Link> </li>
                <li> <Link to="/request-reset-pass">reset password</Link> </li>
            </ul>

        </form>
        </>
    );
};

export default EmailRegistrationForm;
