import React            from 'react'
import styles           from './SigninProviderPage.module.css'
import EmailSigninForm  from '../components/EmailSigninForm'


const SigninProviderPage = () => {

    const handleSigninByAuthProvider = (provider) => {
        window.location.href = `${process.env.REACT_APP_API_ENDPOINT}/api/auth/${provider}`;
    };

    return (
        <>
        <div className={styles.page}>

            <h2 className={styles.title}>Sign In to Continue</h2>

            <div className={styles.interface}>


                <div className={styles.container}>
                    <h3>Via OAuth Provider</h3>

                    <button
                        onClick={() => handleSigninByAuthProvider('google')}
                        className={`${styles.oauthButton} ${styles.google}`}
                    >
                        <img src="/img/google-color.svg" width="25px" alt="Google" className={styles.icon} />
                        Google
                    </button>

                </div>

                <div className={styles.container}>
                    <h3>Via Email</h3>
                    <EmailSigninForm />
                </div>
            </div>

        </div>
        </>
    );
}

export default SigninProviderPage;
