import React from 'react'

const LoveCompatibilityFunnelPage = () => {
    return (
        <>
        </>
    )
}

export default LoveCompatibilityFunnelPage
