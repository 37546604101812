import React from 'react';
import Styles from './GlassCard.module.css';

const GlassCard = ({ title, subtitle, attributes, price }) => {
  return (
    <>
    <div className={Styles.card}>

      <h2 className={Styles.cardTitle}>{title}</h2>

      <h3 className={Styles.cardSubtitle}>{subtitle}</h3>

      <ul className={Styles.cardAttributes}>
        {attributes.map((attr, index) => (
          <li key={index}>{attr}</li>
        ))}
      </ul>

      <h2 className={Styles.price}> {price} </h2>

      <button className={Styles.cardButton}>Order Now</button>

    </div>
    </>
  );
};

export default GlassCard;
