import React, { useState, useEffect }   from 'react'
import axiosInstance                    from '../utils/axiosInstance'
import styles                           from './UserProfile.module.css'

// import { formatShortDate }              from '../utils/utils'
// <li>{formatShortDate(profile.dob)}</li>

const UserProfile = () =>
{
    const [profile, setProfile] = useState({
        name:           '',
        dob:            '',
        pob:            { name: '' },
        sex:            '',
        starsign:       '',
        profilePic:     ''
    });

    const [isLoaded, setIsLoaded]           = useState(false);
    const [statusMessage, setStatusMessage] = useState('Loading profile..');


    const getProfilePic = () => {

        // if (profile?.profilePic)
        //     return profile.profilePic;

        switch (profile?.sex)
        {
        case 'male':
            return '/img/squeezed-default-male-profile.jpg';

        default:
            return '/img/squeezed-default-female-profile.jpg';
        }
    };

    useEffect(() => {
        const fetchProfile = async () => {
            try
            {
                const response = await axiosInstance.get('/api/id/getAccountIdentity');

                if (response?.data?.identity)
                {
                    setProfile(response.data.identity);
                    setIsLoaded(true);
                }
            }
            catch (error)
            {
                setIsLoaded(false);
                console.error(`⚠ Error fetching profile data:\n${error}\n\n`);
                setStatusMessage('Error fetching profile');
            }
        };

        fetchProfile();
    }, [isLoaded]);


    return (
    <div className={styles.profileContainer}>
        {
            (! isLoaded) ? (
                <p>{ statusMessage }</p>
            ) : (
            <>
                <div className={styles.profilePicContainer}>
                    <img
                        src={getProfilePic()}
                        alt={`${profile.name}'s profile`}
                        className={styles.profilePic}
                    />
                </div>
                <h3 className={styles.profileName}>{profile.name}</h3>
                <ul className={styles.profileDetails}>
                    <li>{profile.starsign}</li>
                    <li>{profile.pob.name}</li>
                </ul>
            </>
        )}
    </div>
);
}

export default UserProfile;
