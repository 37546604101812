import React    from 'react'
import Styles   from './AdminCommonStyles.module.css'


const AdminStatsBox = ({ cfg, color='#fff', bgColor='#007BFF' }) => {

    const {title, majorLabel, minorLabel, data} = cfg;

    return (
    <>
    <div className={Styles.statBox} style={{ color: color, backgroundColor: bgColor }}>

        <h2>{title}</h2>

        <div className={Styles.mainNumber}>
            <span className={Styles.bigNumber}>{data ? data.bigNumber : 0}</span>
            <p className={Styles.mainLabel}>{majorLabel}</p>
        </div>

        <div className={Styles.minorNumbers}>
            {data && data.minor.map((value, i) => (
                <div key={i} className={Styles.minorNumberContainer}>
                    <span className={Styles.minorNumber}>{value}</span>
                    <p className={Styles.minorLabel}>{minorLabel?.[i]}</p>
                </div>
            ))}
        </div>
    </div>
    </>
    );
};

export default AdminStatsBox;
