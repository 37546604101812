import React, { useState, useEffect }   from 'react'
import { useNavigate }                  from 'react-router-dom'
import { startJourney, processStep }    from '../session/journeyState'
import Spinner                          from './Spinner'
import Styles                           from './HeroSection2.module.css'


const HeroSection2 = () => {

    const navigate                      = useNavigate();

    const [isJoining, setIsJoining]     = useState(false);

    const [listMember, setListMember] =
        useState(localStorage.getItem('MailingList') === 'true');

    const handleJoinNow = () => {
        setIsJoining(true);

        localStorage.setItem('MailingList', 'true');
        setListMember(true);

        startJourney('mailing-list-subscribe');
        processStep(navigate);
    }

    useEffect(() => {
        const handleStorageChange = () => {
            setListMember(localStorage.getItem('MailingList') === 'true');
        };

        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);


    return (
    <>
    <div id="HeroSection" className={Styles.heroSection}>
        <div className={Styles.imageContainer}>
            <img
                src="/img/squeezed-HeroSection.webp"
                alt="StarGoddess is your personal resource astrology readings & horoscopes"
                className={Styles.starGoddessImage}
                loading="lazy"
            />
        </div>

        <div className={Styles.titleContainer}>
            <h1>StarGoddess Astrology</h1>
            <ul>
                <li>Daily Forecasts</li>
                <li>Year-Ahead Readings</li>
                <li>Personalized Readings</li>
            </ul>
        </div>

        <div className={Styles.textContainer}>
            <h2>
                { listMember ?
                    `You're a daily horoscope subscriber` :
                    'Get your free daily horoscopes' }
            </h2>

            <button
                disabled={isJoining || listMember}
                onClick={handleJoinNow}
                className={Styles.ctaButton}
            >
                {
                    isJoining ?
                    <Spinner sizeClass="tiny" /> : (listMember ? 'Thank You' : 'Join Now')
                }
            </button>
        </div>
    </div>
    </>
    )
}

export default HeroSection2
