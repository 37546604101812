import React        from 'react'
import { Navigate } from 'react-router-dom'
import { useAuth }  from '../auth/AuthContext'


const ProtectedRoute = ({ children, role }) => {

    const { isAuthenticated, isAdmin } = useAuth();

    if (! isAuthenticated) {
        return <Navigate to="/" replace />;
    }

    if (role && role === "admin" && ! isAdmin) {
        return <Navigate to="/" replace />;
    }

    return children;
};

export default ProtectedRoute
