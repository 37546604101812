import React, { useState, useEffect } from 'react'
import styles from './CookieConsentModal.module.css'

const CookieConsentModal = ({ onConsent }) => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const consent = localStorage.getItem('cookieConsent');
        if (!consent) {
            setIsVisible(true);
        }
    }, []);

    const handleConsent = () => {
        localStorage.setItem('cookieConsent', 'true');
        setIsVisible(false);

        if (onConsent)
            onConsent();
    };

    if (!isVisible) return null;

    return (
        <>
        <div className={styles.modal}>
            <div className={styles.modalContent}>
                <p>By continuing to use our site, you agree to our use of cookies.</p>
                <button onClick={handleConsent} className={styles.button}>I Agree</button>
            </div>
        </div>
        </>
    );
};

export default CookieConsentModal
