import React            from 'react'
import { useAuth }      from '../auth/AuthContext'
import UserDashboard    from '../components/UserDashboard'
import AdminDashboard   from '../components/AdminDashboard'


const DashboardPage = () => {

    // TODO: seperate this into different routes;
    // we already do this isAdmin dance in the ProtectedRoute component.
    const { isAdmin } = useAuth();

    return isAdmin ?
        <AdminDashboard /> : <UserDashboard />;

};

export default DashboardPage
