import React, { useState, useCallback, useEffect }  from 'react'
import { useNavigate }                              from 'react-router-dom'

import DatePicker                                   from 'react-date-picker'
import TimePicker                                   from 'react-time-picker'

import axios                                        from 'axios'
import axiosInstance                                from '../utils/axiosInstance'

import { useAuth }                                  from '../auth/AuthContext'

import { processStep }                              from '../session/journeyState'
import CustomButton                                 from '../components/CustomButton'
import { getStarsign }                              from '../utils/astro'
import { debounce, b64Encode }                      from '../utils/utils'
import { emptyIdent, validateIdentity }             from '../json/Identity'

import styles from './GetPersonalInfoPage.module.css'
import 'react-date-picker/dist/DatePicker.css'
import 'react-calendar/dist/Calendar.css'
import 'react-time-picker/dist/TimePicker.css'


const GetPersonalInfoPage = () => {

    const navigate = useNavigate();

    const { isAuthenticated }                       = useAuth();

    const [currentStep, setCurrentStep]             = useState(1);
    const [identity, setIdentity]                   = useState({...emptyIdent});

    const [dateOfBirth, setDateOfBirth]             = useState(new Date());
    const [timeOfBirth, setTimeOfBirth]             = useState('12:00');

    const [placeSuggestions, setPlaceSuggestions]   = useState([]);
    const [query, setQuery]                         = useState('');
    const [error, setError]                         = useState('');
    const [errors, setErrors]                       = useState({});



    const fetchPlaceSuggestions = async (query) => {
        try
        {
            const response = await axios.get(
                `https://nominatim.openstreetmap.org/search?format=json&q=${query}`
            );
            setPlaceSuggestions(response.data);
        }
        catch (error)
        {
            console.error('Error fetching place suggestions:', error);
        }
    };

    const debounceFetchPlaceSuggestions = useCallback(
        debounce(async (query) => {
            await fetchPlaceSuggestions(query);
        }, 300),
        []
    );

    const handlePlaceSelect = (place) => {
        setIdentity({
            ...identity,
            pob: {
                name: place.display_name,
                lat: place.lat,
                long: place.lon
            }
        });
        setPlaceSuggestions([]);
    };

    const handleUpdateBirthdate = (date, time) => {
        if (date) {
            // Assuming `date` is a JavaScript Date object
            const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
            setDateOfBirth(utcDate);
            setIdentity({
                ...identity,
                dob: utcDate.toISOString()
            });
        }

        if (time) {
            setTimeOfBirth(time);

            const [hours, minutes] = time.split(':');

            const updatedDate = new Date(dateOfBirth);
            updatedDate.setUTCHours(hours, minutes);

            setIdentity({
                ...identity,
                dob: updatedDate.toISOString(),
                starsign: getStarsign(updatedDate)
            });
        }
    };




    const handleNext = () => {
        setCurrentStep(currentStep + 1);
    };

    const handleBack = () => {
        setCurrentStep(currentStep - 1);
    };


    const handleSubmit = async (event) => {

        event.preventDefault();

        const ident = {...identity, name: `${identity.firstName} ${identity.surName}` };

        setIdentity(ident);

        const validationErrors = validateIdentity(ident);

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            setError("Please complete all required fields.");
            return;
        }

        try
        {
            await axiosInstance.post('/api/id/saveAccountIdentity', {
                identity: b64Encode(JSON.stringify(ident))
            });

            processStep(navigate);
        }
        catch (err)
        {
            setError('An error occurred while saving your information.');
            console.error(err);
        }
    };



    useEffect(() => {
        const fetchIdentity = async () => {
            try
            {
                const response = await axiosInstance.get('/api/id/getAccountIdentity');
                const identity = response?.data?.identity;

                if (identity)
                {
                    setIdentity(identity);

                    const dob = new Date(identity.dob);
                    setDateOfBirth(dob);
                    setTimeOfBirth(`${dob.getHours()}:${dob.getMinutes()}`);
                }
            }
            catch (err)
            {
                console.error(`⚠ Exception in fetchIdentity:\n${err}\n\n`);
            }
        }

        if (isAuthenticated)
            fetchIdentity();

    }, [isAuthenticated]);

    useEffect(() => {
        if (query) {
            debounceFetchPlaceSuggestions(query);
        }
    }, [query, debounceFetchPlaceSuggestions]);



    return (
        <>
        <div className={styles.page}>

            <h2>Personalize Your Starsign Readings</h2>

            {error && <div className={styles.error}>{error}</div>}

            <div className={styles.container}>
                <div className={styles.paragraphContainer}>
                    <h3>Please Provide your Birth Details</h3>
                    <p>
                        Accurate birth details allow us to calculate your astrological chart precisely,
                        providing you with insights that are unique to you.
                    </p>
                </div>

                <form className={styles.formContainer}>
                    { currentStep === 1 && (
                        <>
                        <div className={styles.currentStep}>
                            <div>
                                <div className={styles.formGroup + " " + styles.horizontal}>
                                    <div className={styles.formGroup}>
                                        <label>Name:</label>
                                        <input
                                            type="text"
                                            placeholder="First Name"
                                            value={identity.firstName}
                                            onChange={(e) => setIdentity({ ...identity, firstName: e.target.value })}
                                            className={errors.firstName ? styles.errorField : ''}
                                        />
                                    </div>

                                    <div className={styles.formGroup}>
                                        <label>&nbsp;</label>
                                        <input
                                            type="text"
                                            placeholder="Family Name"
                                            value={identity.surName}
                                            onChange={(e) => setIdentity({ ...identity, surName: e.target.value })}
                                            className={errors.surName ? styles.errorField : ''}
                                        />
                                    </div>
                                </div>
                                {errors.name        && <div className={styles.error}>{errors.name}</div>}
                                {errors.firstName   && <div className={styles.error}>{errors.firstName}</div>}
                                {errors.surName     && <div className={styles.error}>{errors.surName}</div>}
                            </div>

                            <div className={styles.formGroup} style={{ marginTop: '-6em' }}>
                                <label>Sex:</label>
                                <select
                                    value={identity.sex}
                                    onChange={(e) => setIdentity({ ...identity, sex: e.target.value })}
                                    className={errors.sex ? styles.errorField : ''}
                                >
                                    <option value="">Select</option>
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                </select>
                                {errors.sex && <div className={styles.error}>{errors.sex}</div>}
                            </div>

                            <div className={styles.horizontal}>
                                <CustomButton type="button" onClick={handleNext}>Next</CustomButton>
                            </div>
                        </div>
                        </>
                    )}

                    { currentStep === 2 && (
                        <>
                            <div className={styles.currentStep}>
                                <div className={styles.formGroup}>
                                    <label>Place of Birth:</label>
                                    <input
                                        type="text"
                                        value={identity.pob.name}
                                        onChange={(e) => {
                                            setQuery(e.target.value);
                                            setIdentity({ ...identity, pob: { ...identity.pob, name: e.target.value } });
                                        }}
                                        className={errors.pob ? styles.errorField : ''}
                                    />
                                    {errors.pob && <div className={styles.error}>{errors.pob}</div>}
                                    <ul className={styles.suggestions}>
                                        {placeSuggestions.map((place) => (
                                            <li key={place.place_id} onClick={() => handlePlaceSelect(place)}>
                                                {place.display_name}
                                            </li>
                                        ))}
                                    </ul>
                                </div>

                                <div className={styles.horizontal}>
                                    <CustomButton type="button" onClick={handleBack}>Back</CustomButton>
                                    <CustomButton type="button" onClick={handleNext}>Next</CustomButton>
                                </div>
                            </div>
                        </>
                    )}

                    { currentStep === 3 && (
                        <>
                            <div className={styles.currentStep}>
                                <div>
                                    <label>Date & Time of Birth:</label>
                                    <div className={styles.horizontal}>
                                        <DatePicker
                                            onChange={(date) => { handleUpdateBirthdate(date, timeOfBirth); }}
                                            value={dateOfBirth}
                                            required
                                            className={errors.dob ? styles.errorField : ''}
                                        />

                                        <TimePicker
                                            onChange={(time) => { handleUpdateBirthdate(dateOfBirth, time); }}
                                            value={timeOfBirth}
                                            disableClock
                                            required
                                            className={errors.dob ? styles.errorField : ''}
                                        />
                                    </div>
                                    {errors.dob && <div className={styles.error}>{errors.dob}</div>}
                                </div>

                                <div className={styles.horizontal}>
                                    <CustomButton type="button" onClick={handleBack}>Back</CustomButton>
                                    <CustomButton type="button" onClick={(e) => handleSubmit(e)}>Submit</CustomButton>
                                </div>
                            </div>
                        </>
                    )}
                </form>
            </div>
        </div>
        </>
    );
};

export default GetPersonalInfoPage;
