import React, { useEffect, useState }   from 'react'
import { useParams, useNavigate }       from 'react-router-dom'
import axios                            from 'axios'
import Spinner                          from '../components/Spinner'
import { b64Decode }                    from '../utils/utils'

import styles from './ConfirmAccountPage.module.css'


const ConfirmAccountPage = () => {

    const { regUuid }           = useParams();
    const navigate              = useNavigate();
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('Activating your account..');


    useEffect(() => {

        const confirmAccount = async () => {

            let response;

            try
            {
                setLoading (true);

                const url = (process.env.REACT_APP_NODE_ENV === 'development') ?
                    `${process.env.REACT_APP_API_ENDPOINT}/api/auth/confirmNewAccount?s=${regUuid}` :
                    `${process.env.REACT_APP_BASE_URL}/api/auth/confirmNewAccount?s=${regUuid}` ;

                response = await axios.get(url);

                if (response.status === 201)
                {
                    setMessage('Account confirmed..one moment..');

                    const authCode = b64Decode(response?.data?.authCode);

                    const destinationUrl =
                        `${process.env.REACT_APP_BASE_URL}/auth-success?authCode=${authCode}`;

                    // TODO: figure out why navigate won't work here?
                    window.location.href = destinationUrl;
                }
            }
            catch (error)
            {
                console.error(`⚠ Exception in ConfirmAccountPage:\n${error}\n\n`);

                if (response)
                    console.error(`response:\n${JSON.stringify(response)}\n\n`);

                setMessage('An error occurred while confirming your account.');
            }
            finally
            {
                setLoading (false);
            }
        };

        confirmAccount();

    }, [navigate, regUuid]);


    return (
        <div className={styles.container}>
            {loading ? <Spinner sizeClass="small" /> : null}
            <p>{message}</p>
        </div>
    );
};

export default ConfirmAccountPage
