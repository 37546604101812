import React, { useState, useEffect }   from 'react'
import { useNavigate }                  from 'react-router-dom'

import axiosInstance                    from '../utils/axiosInstance'
import { useAuth }                      from '../auth/AuthContext'
import { startJourney, processStep }    from '../session/journeyState'
import Spinner                          from './Spinner'

import styles from './SubscribeBox3.module.css'

const SubscribeBox3 = () => {

    const { isAuthenticated }               = useAuth();
    const [submitting, setSubmitting]       = useState(false);
    const [message, setMessage]             = useState('');
    const [isSubscribed, setIsSubscribed]   = useState(() => {
        return localStorage.getItem('MailingList') === 'true';
    });
    const [loading, setLoading]             = useState(true);
    const navigate                          = useNavigate();

    useEffect(() => {

        const fetchSubscriptionStatus = async () => {

            if ( ! isAuthenticated)
            {
                setLoading(false);
                return;
            }

            try
            {
                const response = await axiosInstance.get('/api/subscription/status');
                setIsSubscribed(response?.data?.isSubscribed);

                localStorage.setItem('MailingList',
                    response?.data?.isSubscribed ? 'true' : 'false');
            }
            catch (err)
            {
                console.error('Failed to fetch subscription status:', err);
            }
            finally
            {
                setLoading(false);
            }
        };

        fetchSubscriptionStatus();
    }, [isAuthenticated]);

    const subscribe = async () => {
        try
        {
            setSubmitting(true);

            await axiosInstance.get('/api/subscription/subscribe');
            setMessage('Thank you so much!');
            setIsSubscribed(true);

            localStorage.setItem('MailingList', 'true');
        }
        catch (error)
        {
            setMessage(`Subscription failed. Please try again later.`);
            console.error('Subscription error:', error);
        }
        finally
        {
            setSubmitting(false);
            setLoading(false);
        }
    };

    const handleSubscribe = async () => {

        setLoading(true);

        if (isAuthenticated)
        {
            await subscribe();
        }
        else
        {
            startJourney('mailing-list-subscribe');
            processStep(navigate);
        }
    };

    if (loading)
    {
        return (
            <>
            <div className={styles.subscribeBox}>
                <Spinner sizeClass="small" />
                <h3>loading..</h3>
            </div>
            </>
        );
    }

    if (isSubscribed)
    {
        return null;

        /*return (
            <>
            <div className={styles.subscribeBox}>
                <h3>StarGoddess Subscriber</h3>
                <div>💗💖😍💖💗</div>
            </div>
            </>
        );*/
    }

    return (
        <>
        <div className={styles.subscribeBox}>
            <h3>Get your free daily horoscope forecast</h3>
            <h3>direct to your inbox</h3>
            <button onClick={handleSubscribe} disabled={submitting} className={styles.button}>
                {submitting ? 'Subscribing..' : 'Yes Please!'}
            </button>
            {message && <p className={styles.message}>{message}</p>}
        </div>
        </>
    );
};

export default SubscribeBox3
