import React  from 'react'
import Styles from './LoveCompatibilitySection.module.css'

const LoveCompatibilitySection = () => {

    const handleCheckCompatibility = (e) => {
        e.preventDefault();
        return;
    }

    return (
    <>
    <div id="LoveCompatibilitySection" className={Styles.loveCompatibilitySection}>

        <div className={Styles.imageContainer}>
            <img src="/img/lovers.jpg" alt="Love Compatibility" className={Styles.loveImage} loading="lazy" />
        </div>

        <div className={Styles.textContainer}>

            <h3>Is love in the air?  find out with the..</h3>
            <h2>Star Goddess Love Compatibility</h2>

            <p>
            Unlock the secrets of your astrological compatibility and discover the magic
            between you and your partner.
            </p>

            <p>
            Experience the magic of personalized love compatibility readings with StarGoddess!
            Discover the unique strengths that bring you and your partner closer, and uncover
            potential challenges before they arise. Our insights will guide you towards a more
            harmonious and fulfilling relationship, lighting the way to a love that's written
            in the stars.
            </p>

            <div className={Styles.callToAction}>
                <button className={Styles.ctaButton} onClick={handleCheckCompatibility}>Coming Soon</button>
            </div>

        </div>

    </div>
    </>
    );
};

export default LoveCompatibilitySection;
