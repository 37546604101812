import React from 'react'

import Styles from './Spinner.module.css'

const Spinner = ({ sizeClass = 'small' }) => {
    return (
    <>
        <div className={`${Styles.spinner} ${Styles[sizeClass]}`}></div>
    </>
    )
}

export default Spinner
