import React        from 'react'
import GlassCard    from '../components/GlassCard'
import Styles       from './PersonalReadingFunnelPage.module.css'


const PersonalReadingFunnelPage = () => {

    const cardData = [
        {
            title:      'Silver Tier',
            subtitle:   'Personalised Horoscope Readings Delivered to your Inbox',
            attributes: ['Daily Horoscope'],
            price:      '$9.99 p/m'
        },

        {
            title:      'Gold Tier',
            subtitle:   'Personalised Horoscope Readings Delivered to your Inbox',
            attributes: ['Daily Horoscope', 'Weekly Horoscope'],
            price:      '$12.49 p/m'
        },

        {
            title:      'Platinum Tier',
            subtitle:   'Personalised Horoscope Readings Delivered to your Inbox',
            attributes: ['Daily Horoscope', 'Weekly Horoscope', 'Monthly Horoscope', 'Annual Horoscope'],
            price:      '$18.49 p/m'
        }
    ];

    return (
    <>
    <div id="top"></div>

    <div className={Styles.page}>

        <img
            src="/img/squeezed-stars-inverted.jpg"
            alt="starfield"
            loading="lazy"
            className={Styles.backgroundImage}
        />

        <div className={Styles.content}>

            <div className={Styles.cardsContainer}>
                {
                    cardData.map((card, index) => (
                        <GlassCard
                            key={index}
                            title={card.title}
                            subtitle={card.subtitle}
                            attributes={card.attributes}
                            price={card.price}
                        />
                    ))
                }
            </div>

        </div>
    </div>
    </>
    );
};

export default PersonalReadingFunnelPage;
