import React, { useState }                          from 'react'
import { useNavigate }                              from 'react-router-dom'
import { HashLink }                                 from 'react-router-hash-link'
import { useAuth }                                  from '../auth/AuthContext'
import { startJourney, setJourneyData }             from '../session/journeyState'
import { continueJourney }                          from '../session/journeyLogic'
import Spinner                                      from '../components/Spinner'

import Styles from './YearAheadFunnelPage.module.css'


const YearAheadFunnelPage = () => {

    const { isAuthenticated }   = useAuth();
    const [loading, setLoading] = useState(false);
    const [consent, setConsent] = useState(false);
    const navigate              = useNavigate();


    const handleStartJourneyAndBuyNow = async () => {
        setLoading(true);
        startJourney("year-ahead-purchase");
        setJourneyData('yearAheadNoRefundConsent', consent);
        await continueJourney(navigate, isAuthenticated);
        setLoading(false);
    };

  return (
    <>
    <div id="top"></div>

    <div className={Styles.page}>

        <div className={Styles.textContainer}>

            <h2>Year Ahead Astrology Reading</h2>
            <h3>Personalized Insights Just for You</h3>
            <p>
              Discover what the cosmos has in store for you with our personalized
              Year Ahead Astrology Reading.
            </p>
            <p>Gain clarity on potential opportunities,
              challenges, and key moments in your personal and professional life.
              Navigate the months ahead with confidence and foresight, tailored to your
              individual astrological profile.
            </p>

            <div className={Styles.buyNowContainer}>

                <div className={Styles.priceContainer}>
                    <div className={Styles.priceTag}>
                        <span className={Styles.amount}>$14.99*</span>
                    </div>
                    <div className={Styles.priceDescription}>*(launch price), normally $19.99</div>
                </div>

                <div className={Styles.agreementContainer}>
                    <input
                        type="checkbox"
                        checked={consent}
                        onChange={e => setConsent(e.target.checked)}
                        id="no-refunds"
                        className={Styles.checkbox}
                    />
                    <label htmlFor="no-refunds" className={Styles.checkboxLabel}>
                        I consent to no refunds on this product *
                    </label>
                </div>

                <button
                        className={Styles.submitButton}
                        onClick={handleStartJourneyAndBuyNow}
                        disabled={loading || !consent} >
                    {loading && <Spinner sizeClass="tiny" />}
                    Buy Now
                </button>

                <div className={Styles.smallPrint}>
                    <HashLink smooth to='/refund-policy#top'>*more info</HashLink>
                </div>
            </div>

        </div>

        <div className={Styles.imageContainer}>
            <img src="/img/squeezed-year-ahead-crystal-ball.jpg"
                 alt="your year ahead astrology reading"
                 className={Styles.image}
                 loading="lazy" />
        </div>

    </div>
    </>
  );
};

export default YearAheadFunnelPage
