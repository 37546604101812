import axios from 'axios';
import { getAccessToken, getRefreshToken, saveTokens, clearTokens } from './authUtils';

// Create an Axios instance
const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_ENDPOINT,
});

// Request interceptor to add the access token to the header
axiosInstance.interceptors.request.use(
    async (config) => {
        const token = getAccessToken();
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Response interceptor to handle token refresh
axiosInstance.interceptors.response.use(

    (response) => {
        return response;
    },

    async (error) => {
        const originalRequest = error.config;

        console.error(`👀 axiosInstance error:\n${JSON.stringify(error, null, 2)}\n\n`);

        // If the error is due to an expired token, try to refresh the token

        if (error.response?.status === 401 && ! originalRequest._retry) {

            originalRequest._retry = true;

            try
            {
                const refreshToken = getRefreshToken();

                if (!refreshToken)
                    throw new Error('No refresh token available');

                const response = await axios.post(
                    `${process.env.REACT_APP_API_ENDPOINT}/api/auth/refreshToken`,
                    { token: refreshToken }
                );

                if (response.status === 200)
                {
                    // Save new tokens
                    const { accessToken } = response.data;
                    saveTokens(accessToken, refreshToken);

                    // Update the original request with the new token and retry
                    originalRequest.headers['Authorization'] = `Bearer ${accessToken}`;
                    return axiosInstance(originalRequest);
                }
            }
            catch (refreshError)
            {
                console.error(`⚠ Failed to refresh access token:\n${refreshError}\n\n`);
                clearTokens();

                // window.location.href = '/'; // Redirect to login page
            }
        }

        return Promise.reject(error);
    }
);

export default axiosInstance;
