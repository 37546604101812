import React, { useEffect, useState }   from 'react'
import Spinner                          from './Spinner'
import axiosInstance                    from '../utils/axiosInstance'
import { formatShortDate }              from '../utils/utils'

import styles from './UserOrderHistory.module.css'


const UserOrderHistory = ({ onOrderSelect }) => {

    const ordersPerPage                 = 10;
    const [orders, setOrders]           = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages]   = useState(1);
    const [loading, setLoading]         = useState(true);

    useEffect(() => {
        const fetchOrderHistory = async () => {

            setLoading(true);

            try
            {
                const response = await axiosInstance.get('/api/shop/orderHistory');

                if (response?.data)
                {
                    const orders = response.data;

                    setTotalPages(Math.ceil(orders.length / ordersPerPage));
                    setOrders(orders);
                }
            }
            catch (error)
            {
                console.error(`⚠ Error fetching orders:\n${error}\n\n`);
            }

            setLoading(false);
        };

        fetchOrderHistory();
    }, [currentPage]);

    const handlePageChange = (newPage) => {
        if (newPage > 0 && newPage <= totalPages) {
            setCurrentPage(newPage);
        }
    };

    const handleViewReading = (order) => {
        if (onOrderSelect)
            onOrderSelect(order.order_request_uuid);
    }


    return (
    <>
    <div className={styles.ordersList}>
        <h2>Your Orders</h2>
        { loading ? (
            <Spinner sizeClass="small" />
        ) : (
            orders.length === 0 ? (
                <p>No orders found</p>
            ) : (
                <>
                <ul>
                    {orders.map(order => (
                        <li
                            key={order._id}
                            onClick={() => order.order_status === 'complete' && handleViewReading(order) }
                            className={styles.orderItem}
                        >
                                { formatShortDate(order.createdAt) } &nbsp;
                                <b>{ order.product.name }</b> &nbsp;
                                order status: {
                                    order.order_status === 'failed' ?
                                        'in-progress' :
                                        (order.order_status === 'paid' ? 'queued' : order.order_status)
                                }
                                &nbsp;
                        </li>
                    ))}
                </ul>

                <div className={styles.pagination}>
                    <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                        Previous
                    </button>

                    <span>Pg {currentPage} of {Math.ceil(orders.length / ordersPerPage)}</span>

                    <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === Math.ceil(orders.length / ordersPerPage)}>
                        Next
                    </button>
                </div>
                </>
            )
        )}
    </div>
    </>
    )
}

export default UserOrderHistory

