import React            from 'react';
//import { Link }         from 'react-router-dom';
import { HashLink }     from 'react-router-hash-link'
import PropTypes        from 'prop-types';

import astrologySigns   from '../json/AstrologySigns.json';

import Styles           from './StarSignCard.module.css';

const findDateRangeForSign = (signName) => {
    const sign =
        astrologySigns.astrologySigns.find(
            sign => sign.name.toLowerCase() === signName.toLowerCase()
        );

    return (sign) ? { dateFrom: sign.dateFrom, dateTo: sign.dateTo } : null;
};

const StarSignCard = ({ starSign, withLink = false }) => {
    const { dateFrom, dateTo } = findDateRangeForSign(starSign);

    if (withLink) {
        return (
            <div className={Styles.starSignCard}>
                <HashLink smooth to={`/forecast/${starSign.toLowerCase()}#top`} className={Styles.starSignLink}>
                    <img
                        className={Styles.starSignImage}
                        src={`/img/starsigns/25/${starSign.toLowerCase()}.png`}
                        alt={`${starSign}`}
                        loading="lazy"
                    />
                </HashLink>
                <p className={`${Styles.centeredText} ${Styles.cardTitle}`}>{starSign}</p>
                <p className={Styles.centeredText}>{dateFrom} - {dateTo}</p>
            </div>
        );
    } else {
        return (
            <div className={Styles.starSignCard}>
                <div className={Styles.starSignLink}>
                    <img
                        className={Styles.starSignImage}
                        src={`/img/${starSign.toLowerCase()}.png`}
                        alt={`${starSign}`}
                    />
                </div>
                <p className={`${Styles.centeredText} ${Styles.cardTitle}`}>{starSign}</p>
                <p className={Styles.centeredText}>{dateFrom} - {dateTo}</p>
            </div>
        );
    }
};

StarSignCard.propTypes = {
    starSign: PropTypes.string.isRequired,
    withLink: PropTypes.bool
};

export default StarSignCard;
